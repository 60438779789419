import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Transition } from "@headlessui/react"
import ShirtVideoBlue from "../videos/shirt-drop/shirt-drop-3-shirt-blue.webm"
import ShirtVideoWhite from "../videos/shirt-drop/shirt-drop-3-shirt-white.webm"
import PDP from "../components/shirt-drop/drop-3/pdp"
import Animation from "../components/shirt-drop/animation"
import ShirtDrop3Main from "../videos/shirt-drop/shirt-drop-3-main.webm"

const ShirtDrop3: React.FC = () => {
  const [skipIntro, setSkipIntro] = useState(false)
  const handleSkipIntro = () => setSkipIntro(true)

  return (
    <Layout bgColor="black">
      <SEO title="OREMOB SHIRT ADA MOON DROP" />

      <div className="relative flex h-screen flex-col items-center justify-center">
        <Transition
          show={!skipIntro}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute inset-0 z-10 flex h-full w-full flex-col items-center justify-center">
            <Animation onSkipIntro={handleSkipIntro} videoSrc={ShirtDrop3Main}/>
          </div>
        </Transition>
        {skipIntro && (
          <div
            className={`absolute top-1/2 left-1/2 -z-[1] flex w-full -translate-x-1/2 -translate-y-1/2 flex-row gap-2 justify-center ${
              !skipIntro ? "invisible" : ""
            }
          `}
          >
            <video controls={false} autoPlay loop muted className="lg:w-[480px] object-contain object-center" src={ShirtVideoWhite}>
              <source src="/path/to/your/video.webm" type="video/webm" />
              Your browser does not support the video tag.
            </video>
            <video controls={false} autoPlay loop muted className="lg:w-[480px] object-contain object-center" src={ShirtVideoBlue}>
              <source src="/path/to/your/video.webm" type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
        <div
          className={`absolute inset-0 flex h-full w-full flex-col items-center justify-center ${
            !skipIntro ? "invisible" : ""
          }`}
        >
          <PDP />
        </div>
      </div>
    </Layout>
  )
}

export default ShirtDrop3
