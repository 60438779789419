import React, { useState } from "react"
import useFetchEventData from "../../../hooks/useFetchEventData"

type Props = {
  nextStep: () => void
  event: string
}

const selectSizeAndColor: React.FC<Props> = ({event}) => {
  const [selectedSize, setSelectedSize] = useState<string | null>(null)
  const [selectedColor, setSelectedColor] = useState<string | null>(null)
  const { eventData } = useFetchEventData<{
    sizes: string[]
    colors: string[]
    urls: Record<string, string>
  }>(event)

  const sizes = eventData?.data?.sizes ?? [];
  const colors = eventData?.data?.colors ?? [];
  const disabled = !selectedSize || !selectedColor;

  const openSelection = () => {
    if(disabled) return;
    const selectedUrl = eventData?.data?.urls[`${selectedSize}_${selectedColor}`]
    window.open(selectedUrl);
  }

  return (
    <div className="w-full h-full flex flex-col gap-4 justify-center items-center font-gravity-wide uppercase">
      <span>Select Shirt size and color</span>
      <div className="w-full flex flex-col gap-4 justify-center items-center font-gravity-wide uppercase">
        <div className="flex flex-row gap-4 justify-center items-center">
          {sizes && <select className="rounded-full" onChange={e => {console.log(e.target.value); setSelectedSize(e.target.value)}}>
            <option disabled selected>Select size</option>
            {sizes.map((size) => <option key={size} value={size}>{size}</option>)}
          </select>}
          {colors && <select className="rounded-full" onChange={e => {console.log(e.target.value); setSelectedColor(e.target.value)}}>
            <option disabled selected>Select color</option>
            {colors.map((color) => <option key={color} value={color}>{color}</option>)}
          </select>}
        </div>
        <button 
          className={`${disabled ? 'opacity-80' : ''} text-md w-[160px] rounded-full bg-green py-2 px-4 text-center font-gravity-wide uppercase leading-normal text-black lg:w-[220px]`} 
          onClick={openSelection} 
          disabled={disabled}
        >Mint</button>
      </div>
    </div>
  )
}

export default selectSizeAndColor
